import commonHelper from '@/app/utility/common.helper.utility';
import addEditEwayBill from './addEditEwayBill';
import { BasicSelect } from 'vue-search-select';
import InvoiceDetails from './invoiceDetails';

export default {
  name: 'generateEwayBill',
  components: {
    addEditEwayBill,
    BasicSelect,
    InvoiceDetails
  },
  watch: {
    currentPage: function() {
      this.getEwayBills();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEwayBills();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      loader: false,
      unsubscribe: null,
      currentPage: 1,
      showModal:false,
      totalRows: null,
      sourceName:{text:'FA'},
      dropDownSourceList: [
        { text: 'FA' }, 
        { text: 'INV' }
      ],
      invoiceNumber: null,
      data: [],
      fields: [
        {
          key: 'invoice_num'
        },
        {
          key: 'trans_doc_no'
        },
        {
          key: 'bill_from'
        },
        {
          key: 'bill_to',
        },
        {
          key: 'supplier'
        },
        {
          key: 'customer'
        }
      ],
      showInvoiceDetailModal:false,
      propData:{
        sourceName:null,
        invoiceId:null
      },
      dataFlag:false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showModal = true;
          this.dataFlag=true
        }
      }
    });
  },
  methods: {
    getEwayBills(){
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        source_name:this.sourceName.text,
        invoice_num:this.invoiceNumber
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getEwayBill', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data=response.data.data.page;
            this.totalRows=response.data.data.total_elements
          } else {
           alert("Something Went Wrong")
          }
        })
        .catch((error) => {
          this.loader = false;
          this.responseMsg = error;
        });
    },
    rowSelected(rowData) {
      this.propData.invoiceId=rowData.invoice_id;
      this.propData.sourceName=this.sourceName.text;
      this.showModal = true;
      this.dataFlag=false;
    },
    getInvoiceNumber(){
      this.showInvoiceDetailModal=true;
    },
    selectedInvoiceDetails(item){
     this.invoiceNumber=item.invoice_num;
     this.showInvoiceDetailModal=false;
    },
    selectedSource(item) {
      this.sourceName.text = item.text;
    },
    showHideModal(flag) {
      this.showModal = flag;
    },
    closeInvoiceDetailModal(flag){
      this.showInvoiceDetailModal = flag;
    },
    clearFilter() {
      this.data=null;
      this.totalRows=null;
      this.invoiceNumber = null;
      this.source=null;
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
